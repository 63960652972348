<template>
  <div id="app">
    <!-- <div id="header">
      <img src="https://merchant-wx.oss-cn-shanghai.aliyuncs.com/merchatapp/logo_kdb.png"/>
      <div class='links'>
        <a class="link" href="http://www.kdbzfu.cn/"><span>开店宝</span></a>
        <a class="link" href="http://www.kdbzfu.cn/about/gongsijieshao/"><span>公司简介</span></a>
        <a class="link" href="http://www.kdbzfu.cn/case/"><span>产品中心</span></a>
      </div>
    </div> -->
    <router-view />
    <div id="footer">
      <div style="width:750px; margin: auto">
        <span style="font-size:2rem">联系我们</span>
        <div class="link-info">
          <span>咨询电话：13262928834</span>
          <span>Email：kf@kdbzfu.cn</span>
          <span>总部地址：上海浦东新区新金桥路28号新金桥大厦6楼</span>
          <span>
            备案号：
            <a
              href="https://beian.miit.gov.cn/"
              style="color: white;"
              target="_blank"
            >
              津ICP备2021003918号
            </a>
          </span>
          <span>Copyright © 2023 开店宝（天津）科技有限公司</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
body {
  margin: 0;
}
#header {
  width: 750px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;

  .links {
    display: flex;
    flex-direction: row;
    .link {
      color: #be9b85;
      text-decoration: none;
      margin-left: 20px;
    }
  }

}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
#footer {
  background-color: #1f1d20;
  padding: 30px;
  color: white;
  .link-info {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    span {
      width: 50%;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
    }
  }

}
</style>
