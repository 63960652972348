<template>
  <div class="klb-page">
    <appProfile
      v-for="(profile, index) in appProfileList"
      :key="index"
      class="profile-item"
      :profile="profile"
    />
  </div>
</template>
<script>
import appProfile from './app-profile'
export default {
  components: {
    appProfile
  },
  data() {
    return {
      appProfileList: [
        {
          appName: '开店客',
          logo: require('@/assets/kdk_logo.png'),
          versionName: 'V1.0.6',
          publishDate: '2022-03-01',
          privateAgreementUrl: 'https://wapp.candypay.com/privateAgre.html',
          userAgreementUrl: 'https://wapp.candypay.com/userAgre.html',
          downloadUrl: 'https://jfapp.oss-cn-hangzhou.aliyuncs.com/tianjinkdb/kaidianke.apk',
          profile: '开店客是一款由开店宝集团推出的商户进件app，在线帮助小微企业查看交易详情、结算卡信息、开通商户进度等',
          previewUrl: [
            require('@/assets/kdk1.png'),
            require('@/assets/kdk2.png'),
            require('@/assets/kdk3.png'),
            require('@/assets/kdk4.png')
          ]
        },
        {
          appName: '开展业',
          logo: require('@/assets/kzy_logo.jpg'),
          versionName: 'V1.0.6',
          publishDate: '2022-03-01',
          privateAgreementUrl: 'https://kdb-pay.kdb-tj.com/yszc/',
          userAgreementUrl: 'https://kdb-pay.kdb-tj.com/fwxy/',
          downloadUrl: 'https://kdb-pay.kdb-tj.com/download/agent.apk',
          profile: '开展业是致力于为服务商带来安全、便捷、高效的移动终端体验的APP。主要功能以数据智能化处理为核心，帮助客户实现包括机具拨发、状态查询、交易查询等实时查询和管理的全系列功能。',
          previewUrl: [
            require('@/assets/kzy1.jpg'),
            require('@/assets/kzy2.jpg'),
            require('@/assets/kzy3.jpg'),
            require('@/assets/kzy4.jpg')
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
    .klb-page {
        margin: auto;
        width: 750px;
        margin-top: 30px;
        margin-bottom: 30px;

        .profile-item {
            margin-bottom: 80px;
        }
    }
</style>
