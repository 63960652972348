import Vue from 'vue'
import Router from 'vue-router'
import klb from '@/components/klb'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: '',
  routes: [
    {
      path: '/',
      name: 'klb',
      component: klb
    }
  ]
})
