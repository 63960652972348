<template>
  <div class="profile">
    <div class="profile-top">
      <img
        class="logo"
        :src="profile.logo"
      >
      <div class="desc">
        <div>
          <div class="title">{{ profile.appName }}</div>
          <div class="version">
            <!-- <span>版本信息：{{ profile.versionName }}</span>
                        <span>上线日期：{{ profile.publishDate }}</span> -->
            <!-- <span> -->
            <a
              style="text-decoration: none; color:#be9b85"
              :href="profile.privateAgreementUrl"
              target="blank"
            >
              <span>《隐私协议》</span>
            </a>
            <a
              style="text-decoration: none; color:#be9b85"
              :href="profile.userAgreementUrl"
              target="blank"
            >
              <span>《用户协议》</span>
            </a>
            <!-- </span> -->
          </div>
        </div>
        <a
          class="download-btn"
          :href="profile.downloadUrl"
          download="kelaiba.apk"
        >
          <div>Android下载</div>
        </a>
      </div>
    </div>

    <div class="profile-content">
      <p class="profile-title">简介</p>
      <div class="profile-txt">{{ profile.profile }}</div>
      <div class="proifle-imgs">
        <img
          v-for="(item, index) in profile.previewUrl"
          :key="index"
          :src="item"
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    profile: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss" scoped>
    .profile {

        .profile-top {
            display: flex;
            flex-direction: row;
            align-items: center;
            .logo {
                width: 100px;
                height: 100px;
            }
            .desc {
                width: 100%;
                margin-left: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                color: #999;
                .title {
                    font-size: 24px;
                    color: #333;
                }
                .version {
                    margin-top: 20px;
                    font-size: 12px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    span {
                        width: 50%;
                        line-height: 20px;
                    }
                }
                .download-btn {
                    color: white;
                    background-color: #24AA42;
                    text-decoration: none;
                    width: 120px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    font-size: 1rem;
                    letter-spacing: 1px;
                    border-radius: 5px;
                }
            }

        }
        .profile-content {
            .profile-title {
                font-size: 18px;
                letter-spacing: 2px;
                color: #333;
                padding-bottom: 20px;
                border-bottom: #eee 1px solid;
                font-weight: bold;
            }
            .profile-txt {
                font-size: 14px;
                padding: 20px;
                background-color: #eee;
                border-radius: 5px;
            }
            .proifle-imgs {
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                img {
                    width: 20%;
                    margin: 10px;

                }
            }
        }
    }
</style>
